<template>
  <div class="c-toolbar-wrapper">
    <div class="c-toolbar">
      <div class="c-header-icon-container">
        <div v-tooltip.bottom="saveButtonTooltip">
          <PrimeButton
            class="c-circular-button mr-4"
            data-testId="btn-save"
            :disabled="showReturnStockDialog !== DialogState.Closed || !isCreditNoteChanged"
            @click="onClickSaveButton"
          >
            <i class="pi pi-check c-success-button c-circular-icon"></i>
            <span class="px-4">{{ t("creditnote.save") }}</span>
          </PrimeButton>
        </div>
        <PrimeButton
          class="c-circular-button"
          data-testId="btn-cancel"
          @click="router.push({ name: 'credit-note-list' })"
        >
          <i class="pi pi-times c-warning-button c-circular-icon"></i>
          <span class="px-4">{{ t("common.cancel") }}</span>
        </PrimeButton>
      </div>
    </div>
  </div>

  <div class="c-overlay" v-if="loading">
    <div class="c-spinner-container">
      <ProgressSpinner />
    </div>
  </div>

  <div class="c-credit-note">
    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-12 xl:col-span-6">
        <Card>
          <template #content>
            <div class="h-full">
              <div class="grid grid-cols-12 gap-4">
                <div class="col-span-12 xl:col-span-6">
                  <div class="col-span-12">
                    <SelectCustomer
                      :selectedCustomerName="currentCreditNote.orderedBy.customerName"
                      @customerSelected="onSearchCustomerSelected"
                    />
                  </div>
                  <div class="col-span-12">
                    <ContactPerson
                      v-model:contactPerson="currentCreditNote.contactPerson"
                      :customerContacts="customerContacts"
                      :customerType="customerType"
                    />
                  </div>
                  <div class="col-span-12">
                    <ContactEmail v-model:email="currentCreditNote.contactPerson.email" />
                  </div>
                </div>
                <div class="col-span-12 xl:col-span-6">
                  <CustomerInfo :customer="currentCreditNote.orderedBy" />
                </div>
              </div>
            </div>
          </template>
        </Card>
      </div>

      <div class="col-span-12 xl:col-span-6">
        <Card>
          <template #content>
            <div class="h-full">
              <div class="formgrid grid grid-cols-12 gap-4">
                <div class="field col-span-12">
                  <CreditReference v-model:creditReference="currentCreditNote.creditReference" />
                </div>
                <div class="field col-span-12">
                  <Comment v-model:comment="currentCreditNote.creditComment" />
                </div>
              </div>
            </div>
          </template>
        </Card>
      </div>

      <div class="col-span-12 xl:col-span-12">
        <Card>
          <template #content>
            <div>
              <div class="grid grid-cols-12 gap-4">
                <div class="col-span-12">
                  <CreditableRows :creditableRows="creditableRows" />
                </div>
                <div class="col-span-12">
                  <AddCreditNoteLine
                    :selectedCustomerNumber="currentCreditNote.orderedBy.customerNumber"
                    :orderedById="currentCreditNote.orderedBy.id"
                    :creditableRows="creditableRows"
                    @addWithoutReferenceClicked="creditNoteCreateStore.addNoReferenceLine"
                  />
                  <CreditNoteFooter :totalSum="totalSumToCredit" />
                </div>
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>
  </div>

  <DecisionDialog
    v-model:visible="shouldShowAskToOpenReturnStockDialog"
    @returnInventoryClicked="openReturnStockDialog"
    @saveCreditNoteClicked="saveCreditNote"
  />

  <ReturnToStockDialog
    v-model:visible="shouldShowReturnStockDialog"
    :creditableRows="creditableRows"
    :newCreditNote="newCreditNote"
    @saveCreditNote="saveCreditNote"
  />

  <UnsavedChangesDialog
    position="top"
    :visible="unsavedChangesDialogVisible"
    :dialogHeader="t('common.unsaved-changes-header')"
    @cancelClicked="stayOnPage"
    @discardClicked="routeToCreditNoteList"
    @saveClicked="onClickSaveButton"
  >
    <template #content>{{ t("common.unsaved-changes-text") }}</template>
    <template #cancelBtn>{{ t("common.cancel") }}</template>
    <template #discardBtn>{{ t("common.discard") }}</template>
    <template #saveBtn>{{ t("common.save") }}</template></UnsavedChangesDialog
  >
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import SelectCustomer from "@/components/credit-note/SelectCustomer.vue";
import { useCreditNoteCreateStore } from "@/stores/CreditNoteCreateStore";
import { storeToRefs } from "pinia";
import CustomerInfo from "@/components/credit-note/CustomerInfo.vue";
import ContactPerson from "@/components/credit-note/ContactPerson.vue";
import ContactEmail from "@/components/credit-note/ContactEmail.vue";
import CreditReference from "@/components/credit-note/CreditReference.vue";
import Comment from "@/components/credit-note/Comment.vue";
import AddCreditNoteLine from "@/components/credit-note/AddCreditNoteLine.vue";
import CreditableRows from "@/components/credit-note/CreditableRows.vue";
import DecisionDialog from "@/components/credit-note/DecisionDialog.vue";
import ReturnToStockDialog from "@/components/credit-note/ReturnToStockDialog.vue";
import { DialogState, useCreditNoteDialogService } from "@/utils/CreditNoteDialogService";
import { NewCreditNote } from "@/models/credit-note/NewCreditNote";
import { useCumulusToast } from "@cumulus/toast";
import { useToast } from "primevue/usetoast";
import { onMounted } from "vue";
import { useClientStore } from "@/stores/ClientStore";
import { useAuth } from "@cumulus/event-bus";
import { CreditNoteLine } from "@/models/credit-note/CreditNoteLine";
import CreditNoteFooter from "@/components/credit-note/CreditNoteFooter.vue";
import { useCreditNoteRouteService } from "@/api/credit-note/CreditNoteRouteService";
import { UnsavedChangesDialog } from "@cumulus/components";
import useValidate from "@vuelidate/core";
import { ReturnProductInfo } from "@/models/credit-note/ReturnProductInfo";
import { SearchCustomer } from "@/models/search/customer/SearchCustomer";

const { t } = useI18n();
const router = useRouter();
const { getUser } = useAuth();
const creditNoteCreateStore = useCreditNoteCreateStore();
const { getById } = useClientStore();
const toast = useCumulusToast(useToast());
const val = useValidate();
const {
  showReturnStockDialog,
  shouldShowAskToOpenReturnStockDialog,
  shouldShowReturnStockDialog,
  askToOpenReturnStockDialog,
  openReturnStockDialog,
} = useCreditNoteDialogService();

const { currentCreditNote, customerContacts, customerType, creditableRows, isCreditNoteChanged } =
  storeToRefs(creditNoteCreateStore);
const { unsavedChangesDialogVisible, stayOnPage, routeToCreditNoteList } = useCreditNoteRouteService(
  isCreditNoteChanged,
  creditNoteCreateStore.clearStore
);

const newCreditNote = ref<NewCreditNote>(new NewCreditNote());
const loading = ref<boolean>(false);

const totalSumToCredit = computed<number>(() =>
  creditableRows.value.filter((credit) => credit.included).reduce((sum, row) => sum + row.sumToCredit, 0)
);

onMounted(async () => {
  try {
    loading.value = true;
    const user = await getUser();
    const clientId = user.getClient().id;

    const client = await getById(clientId);

    creditNoteCreateStore.setClientInformation(client);
    creditNoteCreateStore.setInitialCreditNote();
  } finally {
    loading.value = false;
  }
});

const saveCreditNote = async (returnProducts?: ReturnProductInfo[]) => {
  await creditNoteCreateStore.createCreditNote(returnProducts);
  routeToCreditNoteList();
};

const hasOnlyNoReferenceLines = computed(() => {
  return creditableRows.value.every((row) => row.isNoReferenceLine);
});

const onSearchCustomerSelected = async (customer: SearchCustomer) => {
  try {
    loading.value = true;
    await creditNoteCreateStore.setCustomerFromSearch(customer);
  } finally {
    loading.value = false;
  }
};

const onClickSaveButton = async () => {
  const add = (x: number, y: number) => {
    return x + y;
  };

  const creditNoteLines = CreditNoteLine.mapFromCreditRows(creditableRows.value);

  currentCreditNote.value.creditNoteLines = creditNoteLines;
  currentCreditNote.value.sumTotalLines = creditNoteLines.map((x) => x.sumLine).reduce(add, 0);
  currentCreditNote.value.totalSum = creditNoteLines.map((x) => x.sumLine).reduce(add, 0);
  currentCreditNote.value.totalVatAmount = creditNoteLines.map((x) => x.sumLineVatAmount).reduce(add, 0);
  currentCreditNote.value.totalSumIncVat = creditNoteLines.map((x) => x.sumLineIncVat).reduce(add, 0);

  if (currentCreditNote.value.creditNoteLines.length === 0) {
    toast.add({
      severity: "warn",
      summary: t("creditnote.add.toast.warn"),
    });
    return;
  }

  val.value.$touch();
  await val.value.$validate();

  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("common.validation-error.summary"),
      detail: t("common.validation-error.detail"),
    });
    return;
  }

  if (hasOnlyNoReferenceLines.value) {
    await saveCreditNote();
  } else {
    askToOpenReturnStockDialog();
  }
};

const saveButtonTooltip = computed(() => {
  return isCreditNoteChanged.value
    ? { value: "" }
    : { value: t("common.save-tooltip"), showDelay: 100, hideDelay: 300 };
});
</script>

<style scoped lang="scss">
.c-credit-note {
  margin: var(--default-content-margin);
}

.c-spinner-container {
  position: relative;
  top: 175px;
}
.c-circular-button:disabled {
  opacity: 0.6;
}
</style>
