<template>
  <label class="c-customer-info-label">{{ t("creditnote.customer.customer-info") }}</label>
  <table>
    <tr>
      <td class="w-full text-sm" v-if="customer.id">
        <span data-testid="customer-name">{{ customer.customerName }}</span> /
        <span data-testid="customer-number">{{ customer.customerNumber }}</span>
      </td>
      <td class="w-full text-sm" v-else>
        <span>
          {{ t(`creditnote.customer.no-customer-selected`) }}
        </span>
      </td>
    </tr>
    <tr>
      <td class="w-full text-sm whitespace-nowrap overflow-hidden text-ellipsis" data-testid="customer-address">
        <span v-for="(line, index) in customer.address.addressLines" :key="line">
          {{ index > 0 ? ", " : "" }}{{ line }}</span
        >
      </td>
    </tr>
    <tr>
      <td class="w-full text-sm">
        <span data-testid="customer-postal-code" class="mr-1">{{ customer.address.postalCode }}</span>
        <span data-testid="customer-postal-city">{{ customer.address.city }}</span>
      </td>
    </tr>

    <tr>
      <td class="w-full text-sm" v-if="customer.phoneNumber">
        <span>{{ t("creditnote.customer.phone-number") + ": " }}</span
        ><span data-testid="customer-phone-number">{{ customer.phoneNumber }}</span>
      </td>
    </tr>
    <tr>
      <td class="w-full text-sm" v-if="customer.email">
        <span>{{ t("creditnote.customer.email") + ": " }}</span>
        <span data-testid="customer-email">{{ customer.email }}</span>
      </td>
    </tr>
    <small class="p-error" v-if="val.customer.id.$error">{{ val.customer.id.$errors[0].$message }}</small>
  </table>
</template>
<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { CreditNoteCustomer } from "@/models/credit-note/CreditNoteCustomer";
import useVuelidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";

const props = defineProps<{
  customer: CreditNoteCustomer;
}>();

const { t } = useI18n();

const rules = {
  customer: {
    id: {
      required: helpers.withMessage(t("creditnote.customer.required"), required),
    },
  },
};

const val = useVuelidate(rules, props);
</script>

<style lang="scss" scoped>
.c-customer-info-label {
  color: var(--primary-color);
  padding-top: 1.2rem;
  padding-bottom: 0.6rem;
  border-bottom: 2px solid var(--primary-color);
  font-weight: bold;
  margin-bottom: 0.6rem;
  width: 100%;
  display: block;
}
</style>
